<template>
  <v-container>
    <v-row>
      <v-col>
        <v-parallax
          height="400"
          class="rounded-xl mb-6 pa-6"
          :src="require('@/assets/greeting_graphic.png')"
        >
          <v-row
            align="center"
            justify="center"
          >
            <v-col
              cols="12"
            >
              <h1 class="greeting-title mb-4">
                Hi {{ this.userName }},
                <br>welcome to
                <br>AppHub
              </h1>
              <h4 class="greeting-subtitle font-weight-thin">
                Underwriting made simple through intutive tools
                <br>that utilize the power of AI to deliver unparalleled insight.
              </h4>
            </v-col>
          </v-row>
        </v-parallax>
      </v-col>
    </v-row>
    <h2 class="title my-4">Apps ({{ appAccess.length }})</h2>
    <v-row align="stretch">
      <v-col cols="6" xs="6" sm="6" md="4" lg="3" xl="2" v-for="app in appAccess" :key="app.title">
        <v-hover v-slot="{ hover }">
          <v-card
            v-if="app.newWindow"
            :elevation="hover ? 12 : 2"
            :class="{ 'on-hover': hover }"
            :href="app.url"
            target="_blank"
            style="height:100%"
          >
            <v-img
              :alt="app.title"
              class="grow"
              contain
              :src="app.image"
            ></v-img>
            <v-card-title>{{app.title}}</v-card-title>
            <v-card-text
              class="d-none d-sm-flex text--secondary"
            >{{app.text}}</v-card-text>
          </v-card>

          <v-card
            v-else
            :elevation="hover ? 12 : 2"
            :class="{ 'on-hover': hover }"
            :to="app.url"
            style="height:100%"
          >
            <v-img
              :alt="app.title"
              class="grow"
              contain
              :src="app.image"
            />
            <v-card-title>{{app.title}}</v-card-title>
            <v-card-text class="d-none d-sm-flex text--secondary">{{app.text}}</v-card-text>
          </v-card>
        </v-hover>
      </v-col>
      <v-col>
        <v-card v-if="!appAccess.length" class="text-center">
          <v-card-title class="justify-center">
            Currently, you have no apps tied to your account.
          </v-card-title>
          <v-card-text class="text--primary">
            <p>No worries... contact support and we will be sure to help you out.</p>
            <v-btn
              dark
              color="orange"
              href="mailto:support@underwriting.ai"
            >Contact Support</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AppList',
  props: {
    userName: String,
  },
  data() {
    return {
      firstName: 'friend',
      apps: [
        {
          title: 'Portal',
          image: require('@/assets/underwriting_portal.png'), // eslint-disable-line
          url: 'https://portal.underwriting.ai/',
          text: 'Search for businesses or properties to gather insight and information about a given risk.',
          newWindow: true,
          access: false,
        },
        {
          title: 'Reports',
          image: require('@/assets/underwriting_reports.png'), // eslint-disable-line
          url: '/reports',
          text: 'Download custom usage reports to share with your business.',
          newWindow: false,
          access: false,
        },
        {
          title: 'API',
          image: require('@/assets/underwriting_api.png'), // eslint-disable-line
          url: '/api',
          text: 'Discover all the cabilities the FRISS Underwriting Insights API can do for your business.',
          newWindow: false,
          access: false,
        },
        {
          title: 'Activity',
          image: require('@/assets/underwriting_activity.png'), // eslint-disable-line
          url: '/activity/',
          text: 'Get unique insight into your business operations.',
          newWindow: false,
          access: false,
        },
        {
          title: 'Book Snapshot',
          image: require('@/assets/underwriting_book_snapshot.png'), // eslint-disable-line
          url: 'https://snapshot.underwriting.ai/',
          text: 'Get deep data insights on your entire book of business.',
          newWindow: true,
          access: false,
        },
      ],
    };
  },
  computed: {
    // Filters out app access
    appAccess: function () { // eslint-disable-line
      return this.apps.filter((i) => i.access);
    },
    portalAccess() {
      return this.$store.state.portalAccess || false;
    },
    reportAccess() {
      return this.$store.state.reportAccess || false;
    },
    apiAccess() {
      return this.$store.state.apiAccess || false;
    },
    activityAccess() {
      return this.$store.state.activityAccess || false;
    },
    bookSnapshotAccess() {
      return this.$store.state.bookSnapshotAccess || false;
    },
    givenName() {
      // return this.$store.state.user.attributes.given_name;
      return this.$store.state.firstName || 'test';
    },
  },
  created() { // This is real funky, need to fix.
    this.apps[0].access = this.portalAccess;
    this.apps[1].access = this.reportAccess;
    this.apps[2].access = this.apiAccess;
    this.apps[3].access = this.activityAccess;
    this.apps[4].access = this.bookSnapshotAccess;
    this.firstName = this.givenName;
  },
  watch: {
    portalAccess() {
      this.apps[0].access = this.portalAccess;
    },
    reportAccess() {
      this.apps[1].access = this.reportAccess;
    },
    apiAccess() {
      this.apps[2].access = this.apiAccess;
    },
    activityAccess() {
      this.apps[3].access = this.activityAccess;
    },
    bookSnapshotAccess() {
      this.apps[4].access = this.bookSnapshotAccess;
    },
    givenName() {
      this.firstName = this.givenName;
    },
  },
};
</script>

<style scoped>
.greeting-title {
  color:rgba(0,0,0,0.8);
  font-size: 5rem;
  line-height: 1;
  word-break: keep-all;
}
.greeting-subtitle {
  font-size: 1rem;
  color:rgba(0,0,0,0.5);
  line-height: 1.5;
}
</style>
