<template>
  <div class="home">
    <AppHub :userName="userName" />

  </div>
</template>

<script>
// @ is an alias to /src
import AppHub from '@/components/AppHub.vue';

export default {
  name: 'Home',
  props: {
    userName: String,
  },
  metaInfo: {
    title: 'AppHub',
  },
  components: {
    AppHub,
  },
  // computed: {
  //   givenName() {
  //     // return this.$store.state.user.attributes.given_name || null;
  //     return this.$store.state.portalUser.userDetails.firstName || null;
  //   },
  // },
};
</script>
